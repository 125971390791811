import styled from 'styled-components';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import logo from '../assets/crio_logo.png';
import Row from './Row';

const FooterComponent = styled.div`
  color: ${(props) => props.theme.palette.grey[50]};
  text-align: center;
  padding: 30px 0 50px 0;
  width: 100%;
  
  &>div {
    margin: 20px auto;
    align-items: center;
    width: 30%;
    
    img {
      margin: 0 20px 0 40px;
    }
  }
  
  &>p {
    color: #2d92b2;
    margin: 0;
    font-size: 0.8em;
  }
`;

export default function Footer() {
  const { t } = useTranslation();
  return (
    <FooterComponent>
      <Row>
        <img src={logo} alt="CRIO" width="112px" />
        <span>{t('Footer.Version')}</span>
      </Row>
      <p>
        {t('Footer.Powered by CRIO')}
        &copy;
        {t('Footer.Year')}
&nbsp;
        {t('Footer.All Rights Reserved')}
      </p>
    </FooterComponent>
  );
}
